import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Facebook from '../assets/svg/facebook.svg';
import Instagram from '../assets/svg/instagram.svg';
import LinkedIn from '../assets/svg/linkedin.svg';
import Twitter from '../assets/svg/twitter.svg';
import { useSiteOptionsQuery } from '../helpers/useSiteOptionsQuery';
import variables from '../helpers/variables';
import Block from './Shared/Block';
import Button from './Shared/Button';
import IconContainer from './Shared/IconContainer';
import Image from './Shared/Image';
import P from './Shared/P';
import Row from './Shared/Row';
import Section from './Shared/Section';
import Wysiwyg from './Shared/Wysiwyg';

const Footer = () => {
  const [isCookieSet, setIsCookieSet] = useState(true);

  useEffect(() => {
    setIsCookieSet(
      document.cookie.match(/^(.*;)?\s*is_allowed\s*=\s*[^;]+(.*)?$/) !== null
    );
  }, []);

  const onCloseCookie = () => {
    document.cookie =
      'is_allowed=1; path=/; expires=Thu, 30 Dec 2080 12:00:00 UTC;';
    setIsCookieSet(true);
  };

  const data = useSiteOptionsQuery();

  const {
    globalSocial,
    logos,
    offices,
    globalEmail,
    cookieBanner,
  } = data.wp.siteOptions.site_options.global;
  
  return (
    <>
      <FooterSection>
        <FooterRowTop>
          <FooterRowTopBlock>
            <FooterMenuAlt>
              {data.footerMenuAlt.menuItems.nodes.map((node, i) => (
                <FooterMenuAltItem key={i}>
                  <FooterHeading as={Link} to={node.url.replace('/ie/', '/')}>
                    {node.label}
                  </FooterHeading>
                </FooterMenuAltItem>
              ))}
            </FooterMenuAlt>
            <FooterConnect>
              <FooterHeading>Connect with us:</FooterHeading>
              <FooterSocialIcons>
                {globalSocial.linkedin && (
                  <IconContainer>
                    <a
                      href={globalSocial.linkedin}
                      target='_blank'
                      rel='noopener'
                      aria-label='View Mediaworks on LinkedIn'
                    >
                      <LinkedIn />
                    </a>
                  </IconContainer>
                )}
                {globalSocial.facebook && (
                  <IconContainer>
                    <a
                      href={globalSocial.facebook}
                      target='_blank'
                      rel='noopener'
                      aria-label='View Mediaworks on Facebook'
                    >
                      <Facebook />
                    </a>
                  </IconContainer>
                )}
                {globalSocial.instagram && (
                  <IconContainer>
                    <a
                      href={globalSocial.instagram}
                      target='_blank'
                      rel='noopener'
                      aria-label='View Mediaworks on Instagram'
                    >
                      <Instagram />
                    </a>
                  </IconContainer>
                )}
                {globalSocial.twitter && (
                  <IconContainer>
                    <a
                      href={globalSocial.twitter}
                      target='_blank'
                      rel='noopener'
                      aria-label='View Mediaworks on Twitter'
                    >
                      <Twitter />
                    </a>
                  </IconContainer>
                )}
              </FooterSocialIcons>
            </FooterConnect>
          </FooterRowTopBlock>
        </FooterRowTop>
        <Row>
          <Block>
            <FooterLogos>
              {logos.map((logo, i) => (
                <Block key={i} width={1 / 2} smWidth={1 / 3} mdWidth={1 / 6}>
                  <Image image={logo.logo} />
                </Block>
              ))}
            </FooterLogos>
          </Block>
        </Row>
        <Row>
          {offices.map((office, i) => (
            <Block key={i} smWidth={1 / 2} lgWidth={1 / 5}>
              <FooterHeading>{office.name}</FooterHeading>
              <Wysiwyg dangerouslySetInnerHTML={{ __html: office.address }} />
            </Block>
          ))}
        </Row>
      </FooterSection>
      <FooterSectionBottom>
        <Row justifyContent='space-between'>
          <FooterBlock>
            <FooterLink as='a' href={`mailto:${globalEmail}`}>
              {globalEmail}
            </FooterLink>
          </FooterBlock>
          <FooterBlock>
            <FooterLink to='/'>
              &copy; {process.env.GATSBY_COPYRIGHT} {new Date().getFullYear()}.
            </FooterLink>
            {data.footerMenu.menuItems.nodes.map((node, index) => (
              <FooterLink key={index} to={node.url.replace('/ie/', '/')}>
                {node.label}.
              </FooterLink>
            ))}
          </FooterBlock>
        </Row>
        {!isCookieSet && (
          <CookiesContainer>
            <CookiesBanner>
              <CookiesText>{cookieBanner}</CookiesText>
              <Button onClick={() => onCloseCookie()}>Close</Button>
            </CookiesBanner>
          </CookiesContainer>
        )}
      </FooterSectionBottom>
    </>
  );
};

export default Footer;

const FooterSection = styled(Section)`
  padding-bottom: 0;
  padding-top: 2rem;
`;

const FooterRowTop = styled(Row)`
  margin-bottom: 2rem;

  ${variables.breakpoints.xl} {
    margin-top: 0;
  }
`;

const FooterRowTopBlock = styled(Block)`
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  ${variables.breakpoints.md} {
    display: flex;
  }
`;

const FooterMenuAlt = styled.ul`
  margin-bottom: 1rem;
  width: auto;

  ${variables.breakpoints.xs} {
    width: 22rem;
  }

  ${variables.breakpoints.md} {
    width: auto;
  }

  ${variables.breakpoints.xl} {
    margin-bottom: 0;
    margin-right: 4rem;
  }
`;

const FooterMenuAltItem = styled.li`
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }
`;

const FooterConnect = styled.div`
  align-items: center;
  margin-bottom: 1rem;

  ${variables.breakpoints.sm} {
    display: flex;
  }

  ${variables.breakpoints.xl} {
    margin-right: -0.5rem;
    margin-top: 0;
  }
`;

const FooterSocialIcons = styled.div`
  display: flex;
  margin-left: -0.5rem;

  ${variables.breakpoints.sm} {
    margin-left: 0.5rem;
  }
`;

const FooterLogos = styled(Row)`
  margin-bottom: 2rem;
`;

const FooterHeading = styled.h4`
  color: ${variables.css.color.white};
  display: inline-block;
  margin-bottom: 0;
  font-family: ${variables.css.fontFamily};
  font-weight: ${variables.css.fontWeight.bold};
  text-transform: uppercase;
`;

const FooterSectionBottom = styled(Section)`
  background-color: #00108c;
  padding-bottom: 0;
  padding-top: 2rem;
`;

const FooterBlock = styled(Block)`
  ${variables.breakpoints.md} {
    &:first-child {
      flex-shrink: 0;
      width: auto;
    }

    &:last-child {
      flex-shrink: 0;
      width: auto;
    }
  }
`;

const FooterLink = styled(Link)`
  color: ${variables.css.color.white};
  display: inline-block;
  font-family: ${variables.css.fontFamily};
  font-weight: ${variables.css.fontWeight.regular};
  margin-bottom: 1rem;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }

  ${variables.breakpoints.md} {
    margin-bottom: 2rem;
  }
`;

const CookiesContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-family: ${variables.css.fontFamily};
  padding-bottom: 1rem;
  padding-top: 2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;

  &.is-hidden {
    display: none;
  }
`;

const CookiesText = styled(P)`
  margin-bottom: 1rem;
  text-align: center;
`;

const CookiesBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
