import styled from 'styled-components';
import variables from '../../helpers/variables';

type PProps = {
  isColorInverted?: boolean;
};

const P = styled.p<PProps>`
  color: ${(p) =>
    p.isColorInverted ? variables.css.color.black : variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-weight: ${variables.css.fontWeight.light};
  margin-bottom: 1rem;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize.lg};
    margin-bottom: 1.125rem;
  }
`;

export default P;
