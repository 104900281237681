import { createIESiteOptionsQuery } from "./createIESiteOptionsQuery";
import { createUKSiteOptionsQuery } from "./createUKSiteOptionsQuery";

export const useSiteOptionsQuery = () : any => {
  // a file can only contain one static query.
  
  if (process.env.GATSBY_MWSITE == "uk") {
    return createUKSiteOptionsQuery();
  }
  else {
    return createIESiteOptionsQuery();
  }
};
