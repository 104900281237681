import React, {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import Facebook from '../../assets/svg/facebook.svg';
import Instagram from '../../assets/svg/instagram.svg';
import LinkedIn from '../../assets/svg/linkedin.svg';
import Phone from '../../assets/svg/phone.svg';
import Twitter from '../../assets/svg/twitter.svg';
import { useSiteOptionsQuery } from '../../helpers/useSiteOptionsQuery';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import MediaListItem from './MediaListItem';
import NavigationListItem from './NavigationListItem';
import SocialListItem from './SocialListItem';

type Props = {
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  isMenuOpen: boolean;
};

const Menu: FC<Props> = ({ setIsMenuOpen, isMenuOpen }) => {
  const data = useSiteOptionsQuery();

  const {
    globalTelephone,
    globalSocial,
  } = data.wp.siteOptions.site_options.global;

  const menuItems = data.wpMenu.menuItems.nodes;
  const mediaData = data.latestPosts.nodes;

  const [circleRadius, setCircleRadius] = useState(0);

  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const x = Math.max(window.innerWidth, window.innerHeight);
    const radius = Math.sqrt(Math.pow(x, 2) * 2);
    setCircleRadius(radius);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      return;
    }

    // Scroll the menu to the top after it's closed.
    const timeout = setTimeout(function () {
      container.current.scrollTop = 0;
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [isMenuOpen]);

  return (
    <Fragment>
      <Circle circleRadius={circleRadius} isVisible={isMenuOpen} />
      <Container ref={container} isVisible={isMenuOpen}>
        <MenuSection>
          <Row>
            <MenuBlock lgWidth={1 / 2}>
              <ul>
                {menuItems.map((item, i) => (
                  <NavigationListItem
                    key={i}
                    setIsMenuOpen={setIsMenuOpen}
                    isVisible={isMenuOpen}
                    transitionDelay={i / 20}
                    url={item.url.replace('/ie/', '/')}
                    label={item.label}
                  />
                ))}
              </ul>
            </MenuBlock>
            {process.env.GATSBY_MWSITE == "uk" && (
              <MenuBlock lgWidth={1 / 2} xlWidth={1 / 3}>
                <ul>
                  {mediaData.map((item, i) => (
                    <MediaListItem
                      key={i}
                      isVisible={isMenuOpen}
                      transitionDelay={(i + menuItems.length) / 20}
                      {...item}
                    />
                  ))}
                </ul>
              </MenuBlock>
            )}
            <MenuBlock>
              <ul>
                {[
                  <a
                    href={globalSocial.linkedin}
                    target='_blank'
                    rel='noopener'
                    aria-label='View Mediaworks on LinkedIn'
                  >
                    <LinkedIn />
                  </a>,
                  <a
                    href={globalSocial.facebook}
                    target='_blank'
                    rel='noopener'
                    aria-label='View Mediaworks on Facebook'
                  >
                    <Facebook />
                  </a>,
                  <a
                    href={globalSocial.instagram}
                    target='_blank'
                    rel='noopener'
                    aria-label='View Mediaworks on Instagram'
                  >
                    <Instagram />
                  </a>,
                  <a
                    href={globalSocial.twitter}
                    target='_blank'
                    rel='noopener'
                    aria-label='View Mediaworks on Twitter'
                  >
                    <Twitter />
                  </a>,
                  <a
                    href={`tel:${globalTelephone}`}
                    aria-label={`Call Mediaworks on ${globalTelephone}`}
                  >
                    <Phone />
                  </a>,
                ].map((item, i) => (
                  <SocialListItem
                    key={i}
                    isVisible={isMenuOpen}
                    transitionDelay={(menuItems.length + mediaData.length) / 20}
                  >
                    {item}
                  </SocialListItem>
                ))}
              </ul>
            </MenuBlock>
          </Row>
        </MenuSection>
      </Container>
    </Fragment>
  );
};

export default Menu;

type CircleProps = {
  circleRadius: number;
  isVisible: boolean;
};

const Circle = styled.div<CircleProps>`
  background-color: rgba(${variables.rgbCS.blue}, 0.95);
  border-radius: 9999px;
  height: ${(p) => p.circleRadius * 2}px;
  margin-right: 5%;
  position: fixed;
  right: ${(p) => p.circleRadius * -1 + 24}px;
  top: ${(p) => p.circleRadius * -1 + 50}px;
  transform: ${(p) => (p.isVisible ? 'scale(1)' : 'scale(0)')};
  transition-duration: ${(p) => (p.isVisible ? '0.6s' : '0.3s')};
  transition-property: transform;
  width: ${(p) => p.circleRadius * 2}px;
  z-index: 100;

  ${variables.breakpoints.md} {
    margin-right: 6.25%;
  }

  ${variables.breakpoints.lg} {
    margin-left: 7.8125%;
    margin-right: 0;
    left: ${(p) => p.circleRadius * -1}px;
    right: auto;
    top: ${(p) => p.circleRadius * -1 + 164}px;
  }
`;

type ContainerProps = {
  isVisible: boolean;
};

const Container = styled.div<ContainerProps>`
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  padding-top: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-delay: ${(p) => (p.isVisible ? '0s' : '0.3s')};
  transition-property: visibility;
  visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
  z-index: 110;

  &::after {
    background: linear-gradient(
      0deg,
      rgba(${variables.rgbCS.blue}, 0) 0%,
      ${variables.css.color.blue} 40%
    );
    content: ' ';
    display: block;
    height: 0;
    opacity: ${(p) => (p.isVisible ? '1' : '0')};
    padding-bottom: 4rem;
    padding-top: 100px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    transition-delay: ${(p) => (p.isVisible ? '0s' : '0.15s')};
    transition-duration: ${(p) => (p.isVisible ? '0.3s' : '0.15s')};
    transition-property: opacity;
  }

  ${variables.breakpoints.lg} {
    padding-top: 128px;

    &::after {
      display: none;
    }
  }
`;

const MenuSection = styled(Section)`
  padding-top: 0;
`;

const MenuBlock = styled(Block)`
  margin-bottom: 4rem;

  &:first-child {
    margin-top: 4rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${variables.breakpoints.lg} {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      display: none;
    }
  }
`;
