import styled from 'styled-components';
import variables from '../../helpers/variables';

type BlockProps = {
  width?: number;
  xsWidth?: number;
  smWidth?: number;
  mdWidth?: number;
  lgWidth?: number;
  xlWidth?: number;
};

const Block = styled.div<BlockProps>`
  padding-left: 1rem;
  padding-right: 1rem;

  ${variables.breakpoints.md} {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  ${variables.breakpoints.xl} {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  width: ${(p) => (p.width ? `${100 * p.width}%` : '100%')};

  ${(p) =>
    p.xsWidth &&
    `
    ${variables.breakpoints.xs} {
      width: ${100 * p.xsWidth}%;
    }
  `}

  ${(p) =>
    p.smWidth &&
    `
    ${variables.breakpoints.sm} {
      width: ${100 * p.smWidth}%;
    }
  `}

  ${(p) =>
    p.mdWidth &&
    `
    ${variables.breakpoints.md} {
      width: ${100 * p.mdWidth}%;
    }
  `}

  ${(p) =>
    p.lgWidth &&
    `
    ${variables.breakpoints.lg} {
      width: ${100 * p.lgWidth}%;
    }
  `}

  ${(p) =>
    p.xlWidth &&
    `
    ${variables.breakpoints.xl} {
      width: ${100 * p.xlWidth}%;
    }
  `}
`;

export default Block;
