import styled from 'styled-components';
import variables from '../../helpers/variables';

type RowProps = {
  justifyContent?: 'space-between';
  reverse?: boolean;
  isPositionRelative?: boolean;
};

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${(p) => (p.reverse ? 'row-reverse' : 'row')};
  flex-wrap: wrap;
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'normal')};
  margin-left: -1rem;
  margin-right: -1rem;
  position: ${(p) => (p.isPositionRelative ? 'relative' : 'static')};

  ${variables.breakpoints.md} {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  ${variables.breakpoints.xl} {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  /* ${variables.breakpoints.xxl} {
    margin-left: auto;
    margin-right: auto;
    padding-left: 5.625%;
    padding-right: 5.625%;
    max-width: 1920px;
  } */
`;

export default Row;
