import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

type Props = {
  isVisible: boolean;
  transitionDelay: number;
};

const SocialListItem: FC<PropsWithChildren<Props>> = ({
  isVisible,
  transitionDelay,
  children,
}) => {
  return (
    <Container isVisible={isVisible} transitionDelay={transitionDelay}>
      {children}
    </Container>
  );
};

export default SocialListItem;

type ContainerProps = {
  isVisible: boolean;
  transitionDelay: number;
};

const Container = styled.li<ContainerProps>`
  display: inline-block;
  margin-right: 1rem;
  opacity: ${(p) => (p.isVisible ? '1' : '0')};
  transition-delay: ${(p) => (p.isVisible ? `${p.transitionDelay}s` : '0s')};
  transition-duration: ${(p) => (p.isVisible ? '0.4s' : '0.2s')};
  transition-property: opacity;
`;
