export default {
  breakpoints: {
    xs: '@media screen and (min-width: 400px)',
    sm: '@media screen and (min-width: 568px)',
    md: '@media screen and (min-width: 768px)',
    lg: '@media screen and (min-width: 1024px)',
    xl: '@media screen and (min-width: 1280px)',
    xxl: '@media screen and (min-width: 1600px)',
    xxxl: '@media screen and (min-width: 2560px)',
  },
  css: {
    color: {
      black: '#333330',
      blue: '#04159c',
      cyan: '#36dae7',
      grey: '#787878',
      offwhite: '#f5f6f8',
      orange: '#ff8300',
      pink: '#ff00eb',
      red: '#ff0000',
      white: '#ffffff',
      yellow: '#fdd300',
    },
    fontFamily: "'Inter', sans-serif, system-ui",
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      md: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '6rem',
      '8xl': '9rem',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      black: 900,
    },
    lineHeight: 0.9,
  },
  rgb: {
    black: '51 51 48',
    blue: '4 21 156',
    white: '255 255 255',
  },
  rgbCS: {
    black: '51, 51, 48',
    blue: '4, 21, 156',
    white: '255, 255, 255',
  },
  videos: {
    bubbles: 'bubbles',
    console: 'console',
    rocket: 'rocket',
    planet: 'planet',
  },
};
