import styled from 'styled-components';
import variables from '../../helpers/variables';

type ButtonProps = {
  isColorInverted?: boolean;
  isLowercase?: boolean;
};

const Button = styled.button<ButtonProps>`
  background-color: transparent;
  border-bottom: 2px solid transparent;
  border-bottom-color: ${(p) =>
    p.isColorInverted ? variables.css.color.blue : variables.css.color.white};
  border-radius: 0;
  color: ${(p) =>
    p.isColorInverted ? variables.css.color.blue : variables.css.color.white};
  cursor: pointer;
  display: inline;
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize.sm};
  font-weight: ${variables.css.fontWeight.bold};
  padding-bottom: 3px;
  text-transform: ${(p) => (p.isLowercase ? 'none' : 'uppercase')};

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize.md};
    padding-bottom: 1px;
  }
`;

export default Button;
