import { Link } from 'gatsby';
import rafSchedule from 'raf-schd';
import React, {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import BarsBlue from '../assets/svg/bars-blue.svg';
import Bars from '../assets/svg/bars.svg';
import FacebookBlue from '../assets/svg/facebook-blue.svg';
import Facebook from '../assets/svg/facebook.svg';
import InstagramBlue from '../assets/svg/instagram-blue.svg';
import Instagram from '../assets/svg/instagram.svg';
import LinkedInBlue from '../assets/svg/linkedin-blue.svg';
import LinkedIn from '../assets/svg/linkedin.svg';
const LogoBlue = require(`../assets/svg/logo-blue.${process.env.GATSBY_MWSITE}.svg`);
const Logo = require(`../assets/svg/logo.${process.env.GATSBY_MWSITE}.svg`);
import PhoneBlue from '../assets/svg/phone-blue.svg';
import Phone from '../assets/svg/phone.svg';
import TimesBlue from '../assets/svg/times-blue.svg';
import Times from '../assets/svg/times.svg';
import TwitterBlue from '../assets/svg/twitter-blue.svg';
import Twitter from '../assets/svg/twitter.svg';
import MenuContext from '../contexts/MenuContext';
import { useSiteOptionsQuery } from '../helpers/useSiteOptionsQuery';
import variables from '../helpers/variables';
import useViewportWidth from '../hooks/useViewportWidth';
import Menu from './Menu';
import IconContainer from './Shared/IconContainer';

type Props = {
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  isMenuOpen: boolean;
};

const Header: FC<Props> = ({ setIsMenuOpen, isMenuOpen }) => {
  const { thresholds } = useContext(MenuContext);

  const { isViewportDesktop } = useViewportWidth();

  const [lastWindowScrollY, setLastWindowScrollY] = useState(0);
  const [clipTop, setClipTop] = useState(100);
  const [clipBottom, setClipBottom] = useState(100);

  const refContainer = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!isViewportDesktop) {
      return;
    }

    const schedule = rafSchedule(() => {
      if (thresholds.length !== 0 && refContainer.current) {
        const { offsetTop, offsetHeight } = refContainer.current;
        const menuTop = offsetTop;
        const menuBottom = offsetTop + offsetHeight;

        function getRealThresholds() {
          let realThresholds = [];

          // We don't want to manipulate our state itself, so we copy it.
          let copyThresholds = [...thresholds];
          copyThresholds.sort((a, b) => a.top - b.top);

          let thresholdsToMerge = [];

          for (let i = 0; i < copyThresholds.length; i++) {
            const bottom = copyThresholds[i]?.bottom;
            const top = copyThresholds[i + 1]?.top;
            // Allow a buffer of two pixels either side, because sometimes our
            // overlap is out by 1 or 2 pixels.
            if (top >= bottom - 2 && top <= bottom + 2) {
              // Overlap.
              thresholdsToMerge.push(copyThresholds[i]);
              thresholdsToMerge.push(copyThresholds[i + 1]);
            } else {
              // No overlap.
              if (thresholdsToMerge.length > 1) {
                const lastIndex = thresholdsToMerge.length - 1;
                realThresholds.push({
                  top: thresholdsToMerge[0].top,
                  bottom: thresholdsToMerge[lastIndex].bottom,
                });
                thresholdsToMerge = [];
              } else {
                realThresholds.push(copyThresholds[i]);
              }
            }
          }

          return realThresholds;
        }

        // prettier-ignore
        for (let threshold of getRealThresholds()) {
          const { top, bottom } = threshold;

          const clipTopRaw = (1 - (window.scrollY + menuBottom - top) / (menuBottom - menuTop)) * 100;
          const clipTopBounded = Math.max(0, Math.min(clipTopRaw, 100));

          const clipBottomRaw = -((window.scrollY + menuTop - bottom) / (menuBottom - menuTop)) * 100;
          const clipBottomBounded = Math.max(0, Math.min(clipBottomRaw, 100));

          // Only update state when the menu is near a white section.
          // Arbitrary buffer of 128 pixels above and below each white section is
          // included to account for fast scrolling.

          const buffer = 128;

          if (clipTopRaw >= 0 - buffer && clipTopRaw <= 100 + buffer) {
            setClipTop(clipTopBounded);

            if (clipTopBounded === clipBottomBounded) {
              if (lastWindowScrollY < window.scrollY) {
                // Window is scrolling down.
                setClipTop(100);
                setClipBottom(100);
              } else {
                // Window is scrolling up.
                setClipTop(0);
                setClipBottom(0);
              }
            }
          }

          if (clipBottomRaw >= 0 - buffer && clipBottomRaw <= 100 + buffer) {
            setClipBottom(clipBottomBounded);

            if (clipTopBounded === clipBottomBounded) {
              if (lastWindowScrollY < window.scrollY) {
                // Window is scrolling down.
                setClipTop(100);
                setClipBottom(100);
              } else {
                // Window is scrolling up.
                setClipTop(0);
                setClipBottom(0);
              }
            }
          }
        }

        setLastWindowScrollY(window.scrollY);
      }
    });

    // Run when the component first mounts.
    schedule();

    // Run each time the user scrolls.
    window.addEventListener('scroll', schedule);

    return () => {
      window.removeEventListener('scroll', schedule);
    };
  }, [thresholds, isViewportDesktop, lastWindowScrollY]);

  const data = useSiteOptionsQuery();

  const {
    globalTelephone,
    globalSocial,
  } = data.wp.siteOptions.site_options.global;

  return (
    <Fragment>
      <Menu setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
      <Container ref={refContainer}>
        <Link to='/'>
          <Logo />
        </Link>
        <Flex>
          {process.env.GATSBY_MWSITE == "uk" && (
            <IconContainerPhone>
              <a
                href={`tel:${globalTelephone}`}
                aria-label={`Call Mediaworks on ${globalTelephone}`}
              >
                <Phone />
              </a>
            </IconContainerPhone>
          )}
          <IconContainer onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <Times /> : <Bars />}
          </IconContainer>
        </Flex>

        <SocialIconsContainer>
          {globalSocial.linkedin && (
            <IconContainer>
              <a
                href={globalSocial.linkedin}
                target='_blank'
                rel='noopener'
                aria-label='View Mediaworks on LinkedIn'
              >
                <LinkedIn />
              </a>
            </IconContainer>
          )}
          {globalSocial.facebook && (
            <IconContainer>
              <a
                href={globalSocial.facebook}
                target='_blank'
                rel='noopener'
                aria-label='View Mediaworks on Facebook'
              >
                <Facebook />
              </a>
            </IconContainer>
          )}
          {globalSocial.instagram && (
            <IconContainer>
              <a
                href={globalSocial.instagram}
                target='_blank'
                rel='noopener'
                aria-label='View Mediaworks on Instagram'
              >
                <Instagram />
              </a>
            </IconContainer>
          )}
          {globalSocial.twitter && (
            <IconContainer>
              <a
                href={globalSocial.twitter}
                target='_blank'
                rel='noopener'
                aria-label='View Mediaworks on Twitter'
              >
                <Twitter />
              </a>
            </IconContainer>
          )}
          {process.env.GATSBY_MWSITE == "uk" && (
            <IconContainer>
              <a
                href={`tel:${globalTelephone}`}
                aria-label={`Call Mediaworks on ${globalTelephone}`}
              >
                <Phone />
              </a>
            </IconContainer>
          )}
        </SocialIconsContainer>
      </Container>
      <ContainerMask
        isMenuOpen={isMenuOpen}
        clipTop={clipTop}
        clipBottom={clipBottom}
      >
        <LogoBlue />
        <IconContainer>
          {isMenuOpen ? <TimesBlue /> : <BarsBlue />}
        </IconContainer>
        <SocialIconsContainer>
          {globalSocial.linkedin && (
            <IconContainer>
              <LinkedInBlue />
            </IconContainer>
          )}
          {globalSocial.facebook && (
            <IconContainer>
              <FacebookBlue />
            </IconContainer>
          )}
          {globalSocial.instagram && (
            <IconContainer>
              <InstagramBlue />
            </IconContainer>
          )}
          {globalSocial.twitter && (
            <IconContainer>
              <TwitterBlue />
            </IconContainer>
          )}
          {process.env.GATSBY_MWSITE == "uk" && (
            <IconContainer>
              <PhoneBlue />
            </IconContainer>
          )}
        </SocialIconsContainer>
      </ContainerMask>
    </Fragment>
  );
};

export default Header;

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100px;
  padding-bottom: 16px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 16px;
  position: relative;
  z-index: 120;

  ${variables.breakpoints.md} {
    height: 132px;
    padding-bottom: 32px;
    padding-top: 32px;
    padding-left: 6.25%;
    padding-right: 6.25%;
  }

  ${variables.breakpoints.lg} {
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    padding: 0;
    position: fixed;
    left: 0;
    top: 64px;
    width: 15.625%;
  }

  @media screen and (min-width: 2793px) {
    width: calc(50% - 960px);
  }
`;

type ContainerMaskProps = {
  isMenuOpen: boolean;
  clipTop: number;
  clipBottom: number;
};

const ContainerMask = styled(Container).attrs((p: ContainerMaskProps) => ({
  style: {
    clipPath: `polygon(0 ${p.clipTop}%, 100% ${p.clipTop}%, 100% ${p.clipBottom}%, 0 ${p.clipBottom}%)`,
  },
}))<ContainerMaskProps>`
  opacity: ${(p) => (p.isMenuOpen ? 0 : 1)};
  pointer-events: none;
  transition-delay: ${(p) => (p.isMenuOpen ? '0s' : '0.15s')};
  transition-duration: 0.15s;
  transition-property: opacity;
  display: none;

  ${variables.breakpoints.lg} {
    display: flex;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    opacity: 0;
  }
`;

const SocialIconsContainer = styled.div`
  display: none;

  ${variables.breakpoints.lg} {
    display: block;
    margin-top: 10px;
  }
`;

const IconContainerPhone = styled(IconContainer)`
  ${variables.breakpoints.lg} {
    display: none;
  }
`;

const Flex = styled.div`
  display: flex;
`;
