import { graphql,useStaticQuery } from "gatsby";

export const createIESiteOptionsQuery = () : any =>
  useStaticQuery(graphql`
    query {
      wp {
        siteOptions {
          site_options {
            global: ireland {
              globalSiteUrl
              globalTelephone
              globalSocial {
                facebook
                instagram
                twitter
                linkedin
              }
              logos {
                logo {
                  localFile {
                    childImageSharp {
                      # BETA: https://github.com/gatsbyjs/gatsby/discussions/27950
                      gatsbyImageData(
                        layout: CONSTRAINED
                        width: 320
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
              offices {
                name
                address
              }
              globalEmail
              cookieBanner
            }
          }
        }
      }
      footerMenuAlt: wpMenu(slug: { eq: "ireland-footer-menu-alt" }) {
        menuItems {
          nodes {
            label
            url
          }
        }
      }
      footerMenu: wpMenu(slug: { eq: "ireland-footer-menu" }) {
        menuItems {
          nodes {
            label
            url
          }
        }
      }
      wpMenu(slug: { eq: "ireland-main-menu" }) {
        menuItems {
          nodes {
            label
            url
          }
        }
      }
      latestPosts: allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
        nodes {
          title
          link
          date
        }
      }
      
      allWpMenu {
        nodes {
          name
          slug
          menuItems {
            nodes {
              url
            }
          }
        }
      }
    }
  `);