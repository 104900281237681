import styled from 'styled-components';
import variables from '../../helpers/variables';

type H2Props = {
  isColorInverted?: boolean;
};

const H2 = styled.h2<H2Props>`
  color: ${(p) =>
    p.isColorInverted ? variables.css.color.blue : variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize['4xl']};
  font-weight: ${variables.css.fontWeight.black};
  line-height: ${variables.css.lineHeight};
  margin-bottom: 2.25rem;
  text-transform: uppercase;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['5xl']};
    margin-bottom: 3rem;
  }
`;

export default H2;
