import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';
import MenuContextProvider from '../contexts/MenuContextProvider';
import variables from '../helpers/variables';
import Footer from './Footer';
import Header from './Header';

const Layout: FC<ReactNode> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <MenuContextProvider>
      <Container>
        <Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
        {children}
        <Footer />
      </Container>
    </MenuContextProvider>
  );
};

export default Layout;

const Container = styled.div`
  background: ${variables.css.color.blue};

  /* background: radial-gradient(
    circle at 66.666666% 24rem,
    #1f40ff 0%,
    ${variables.css.color.blue} 320px
  );

  ${variables.breakpoints.sm} {
    background: radial-gradient(
      circle at 66.666666% 24rem,
      #1f40ff 0%,
      ${variables.css.color.blue} 480px
    );
  }

  ${variables.breakpoints.md} {
    background: radial-gradient(
      circle at 66.666666% 24rem,
      #1f40ff 0%,
      ${variables.css.color.blue} 768px
    );
  }

  ${variables.breakpoints.lg} {
    background: radial-gradient(
      circle at 66.666666% 24rem,
      #1f40ff 0%,
      ${variables.css.color.blue} 1024px
    );
  }

  ${variables.breakpoints.xl} {
    background: radial-gradient(
      circle at 66.666666% 24rem,
      #1f40ff 0%,
      ${variables.css.color.blue} 1280px
    );
  } */
`;
