import rafSchedule from 'raf-schd';
import { useEffect, useState } from 'react';

export default function useViewportWidth() {
  const [isViewportMobile, setIsViewportMobile] = useState(true);
  const [isViewportDesktop, setIsViewportDesktop] = useState(false);

  useEffect(() => {
    const schedule = rafSchedule(() => {
      setIsViewportMobile(window.innerWidth < 768);
      setIsViewportDesktop(window.innerWidth >= 1024);
    });

    schedule();

    window.addEventListener('resize', schedule);

    return () => {
      window.removeEventListener('resize', schedule);
    };
  }, []);

  return { isViewportMobile, isViewportDesktop };
}
