import { Link } from 'gatsby';
import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';

type Props = {
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  isVisible: boolean;
  transitionDelay: number;
  url: string;
  label: string;
};

const NavigationListItem: FC<Props> = ({
  setIsMenuOpen,
  isVisible,
  transitionDelay,
  url,
  label,
}) => {
  return (
    <Container isVisible={isVisible} transitionDelay={transitionDelay}>
      <NavigationListItemLink onClick={() => setIsMenuOpen(false)} to={url}>
        {label}
      </NavigationListItemLink>
    </Container>
  );
};

export default NavigationListItem;

type ContainerProps = {
  isVisible: boolean;
  transitionDelay: number;
};

const Container = styled.li<ContainerProps>`
  margin-bottom: 1rem;
  opacity: ${(p) => (p.isVisible ? '1' : '0')};
  transition-delay: ${(p) => (p.isVisible ? `${p.transitionDelay}s` : '0s')};
  transition-duration: ${(p) => (p.isVisible ? '0.4s' : '0.2s')};
  transition-property: opacity;

  &:last-child {
    margin-bottom: 0;
  }
`;

const NavigationListItemLink = styled(Link)`
  color: ${variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize['3xl']};
  font-weight: ${variables.css.fontWeight.black};
  text-transform: uppercase;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['4xl']};
  }
`;
