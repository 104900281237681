import styled from 'styled-components';
import variables from '../../helpers/variables';

const IconContainer = styled.div`
  align-items: center;
  color: ${variables.css.color.white};
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  height: 48px;
  justify-content: center;
  width: 48px;
`;

export default IconContainer;
