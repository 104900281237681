import { format } from 'date-fns';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import Button from '../Shared/Button';

type Props = {
  isVisible: boolean;
  transitionDelay: number;
  link: string;
  title: string;
  date: string;
};

const MediaListItem: FC<Props> = ({
  isVisible,
  transitionDelay,
  link,
  title,
  date,
}) => {
  return (
    <Container isVisible={isVisible} transitionDelay={transitionDelay}>
      <FlexContainer>
        <Title>{title}</Title>
        <Description>{format(new Date(date), 'dd MMM')}</Description>
      </FlexContainer>
      <BoldButton as={Link} to={link}>
        Find out more
      </BoldButton>
    </Container>
  );
};

export default MediaListItem;

type ContainerProps = {
  isVisible: boolean;
  transitionDelay: number;
};

const Container = styled.li<ContainerProps>`
  margin-bottom: 3rem;
  opacity: ${(p) => (p.isVisible ? '1' : '0')};
  transition-delay: ${(p) => (p.isVisible ? `${p.transitionDelay}s` : '0s')};
  transition-duration: ${(p) => (p.isVisible ? '0.4s' : '0.2s')};
  transition-property: opacity;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BoldButton = styled(Button)`
  font-weight: ${variables.css.fontWeight.black};
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const Title = styled.h3`
  color: ${variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-size: 1.5rem;
  font-weight: ${variables.css.fontWeight.regular};
  margin-bottom: 0.5rem;
`;

const Description = styled.h4`
  color: ${variables.css.color.pink};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize.sm};
  font-weight: ${variables.css.fontWeight.bold};
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-style: italic;
`;
