import styled from 'styled-components';
import variables from '../../helpers/variables';

type WysiwygProps = {
  isColorBlack?: boolean;
};

const Wysiwyg = styled.div<WysiwygProps>`
  margin-bottom: 2.25rem;
  font-family: ${variables.css.fontFamily};
  font-weight: ${variables.css.fontWeight.light};
  ${variables.breakpoints.lg} {
    margin-bottom: 3rem;
  }

  & p {
    color: ${(p) =>
      p.isColorBlack ? variables.css.color.black : variables.css.color.white};
    font-family: ${variables.css.fontFamily};
    font-weight: ${variables.css.fontWeight.light};
    margin-bottom: 1rem;

    ${variables.breakpoints.lg} {
      font-size: ${variables.css.fontSize.lg};
      margin-bottom: 1.125rem;
    }
  }
  & p:first-of-type > strong {
    color: ${variables.css.color.blue};
  }

  & > iframe {
    width: 100%;
  }
  & ul {
    color: ${(p) =>
      p.isColorBlack ? variables.css.color.black : variables.css.color.white};
    list-style-type: circle;
    margin-bottom: 1rem;
    margin-left: 1rem;

    ${variables.breakpoints.lg} {
      font-size: ${variables.css.fontSize.lg};
      margin-bottom: 1.125rem;
    }
  }
  & ol {
    color: ${(p) =>
      p.isColorBlack ? variables.css.color.black : variables.css.color.white};
    list-style-type: decimal;
    margin-bottom: 1rem;
    margin-left: 1rem;

    ${variables.breakpoints.lg} {
      font-size: ${variables.css.fontSize.lg};
      margin-bottom: 1.125rem;
    }
  }
  & blockquote {
    border-top: 3px solid ${variables.css.color.offwhite};
    border-bottom: 3px solid ${variables.css.color.offwhite};
    color: ${variables.css.color.blue};
    font-size: 1.625rem;
    font-weight: ${variables.css.fontWeight.regular};
    padding-bottom: 3rem;
    padding-left: 5rem;
    padding-top: 3rem;
  }
  & h1 {
    color: ${variables.css.color.blue};
    font-size: 2rem;
    font-weight: ${variables.css.fontWeight.bold};
    margin-bottom: 1rem;

    ${variables.breakpoints.lg} {
      margin-bottom: 1.125rem;
    }
  }
  & h2 {
    color: ${variables.css.color.blue};
    font-size: 1.8rem;
    font-weight: ${variables.css.fontWeight.bold};
    margin-bottom: 1rem;

    ${variables.breakpoints.lg} {
      margin-bottom: 1.125rem;
    }
  }
  & h3 {
    font-size: 1.6rem;
    font-weight: ${variables.css.fontWeight.bold};
    color: ${variables.css.color.blue};
    margin-bottom: 1rem;

    ${variables.breakpoints.lg} {
      margin-bottom: 1.125rem;
    }
  }
  & h4 {
    font-size: 1.4rem;
    margin-bottom: 1rem;

    ${variables.breakpoints.lg} {
      margin-bottom: 1.125rem;
    }
  }
  & h5 {
    font-size: 1.2rem;
    margin-bottom: 1rem;

    ${variables.breakpoints.lg} {
      margin-bottom: 1.125rem;
    }
  }
  & h6 {
    font-size: 1rem;
    margin-bottom: 1rem;

    ${variables.breakpoints.lg} {
      margin-bottom: 1.125rem;
    }
  }
  & strong {
    font-weight: bold;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export default Wysiwyg;
