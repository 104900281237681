import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

type Props = {
  image: any;
};

const Image: FC<Props> = ({ image }) => {
  if (image === null || image === undefined) {
    return null;
  }

  return <GatsbyImage image={getImage(image.localFile)} alt={image.altText} />;
};

export default Image;
