import styled from 'styled-components';
import variables from '../../helpers/variables';
import Row from './Row';

type SectionProps = {
  hasVideo?: boolean;
  isColorInverted?: boolean;
};

const Section = styled.div<SectionProps>`
  background-color: ${(p) =>
    p.isColorInverted ? variables.css.color.white : 'transparent'};
  min-height: ${(p) => (p.hasVideo ? '768px' : 'auto')};
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 4rem;
  padding-top: 4rem;
  position: relative;
  /* max-width: 1920px;
  margin-left: auto;
  margin-right: auto; */

  ${variables.breakpoints.md} {
    padding-bottom: 6rem;
    padding-left: 6.25%;
    padding-right: 6.25%;
    padding-top: 6rem;
  }

  ${variables.breakpoints.lg} {
    padding-bottom: 8rem;
    padding-left: 15.625%;
    padding-right: 15.625%;
    padding-top: 8rem;
  }

  ${variables.breakpoints.xxxl} {
    padding-bottom: 16rem;
    padding-top: 16rem;

    & > ${Row} {
      margin-left: auto;
      margin-right: auto;
      max-width: 1920px;
    }
  }

  /* ${variables.breakpoints.xxl} {
    padding-bottom: 16rem;
    padding-left: 5.625%;
    padding-right: 5.625%;
    padding-top: 16rem;
  } */
`;

export default Section;
