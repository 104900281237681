import React, { FC, ReactNode, useState } from 'react';
import MenuContext from './MenuContext';

type Threshold = {
  id: string;
  top: number;
  bottom: number;
};

const MenuContextProvider: FC<ReactNode> = ({ children }) => {
  const [thresholds, setThresholds] = useState<Threshold[]>([]);

  function addSectionInfo(id: string, top: number, bottom: number) {
    setThresholds((thresholds) => [...thresholds, { id, top, bottom }]);
  }

  function removeSectionInfo(id: string) {
    setThresholds((thresholds) => thresholds.filter((item) => item.id !== id));
  }

  return (
    <MenuContext.Provider
      value={{
        addSectionInfo,
        removeSectionInfo,
        thresholds,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContextProvider;
